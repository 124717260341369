.home-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
}

.home-ingredients-storage,
.home-recipe-calendar {
    border: 1px solid rgb(237, 237, 237);
    border-radius: 10px;
    box-shadow: 2px 2px 5px #d7cbb3;
    padding: 10px;
    margin: 20px;
    width: 80vw;
    background-color: #F5F3EE;
}

