/* PlanMeal.css */
.container {
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.meal-box {
    color: #535353;
    margin-top: 20px;
    border: 1px solid #e0e0e0;
    
    box-shadow: 0 0.5rem 0.5rem 0 #e0e0e0;
    border-radius: 1rem;
    padding: 20px;
    overflow-y: scroll;
    flex-grow: 1; /* Allows the box to grow and take up available space */
    position: relative; /* To position the footer inside it */
    padding-bottom: 0px; /*To leave space at the bottom for the footer*/
    margin-bottom: 80px;
}

.plan-meal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: 1rem;
}

/* h1{
    display: flex;
    font-size: 1.5rem;
    margin: 0;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    color: #CC564D;
    font-weight: 500;
    
    border-bottom: 1px solid #535353;
} */

.notebook-line{
    flex-grow: 1;
    margin-right: 1rem;
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 5px;
    font-size: 16px;   /* Assuming a default size. Adjust as per your design */
    line-height: 1.5; /* Assuming a default line height. Adjust as per your design */
}

.recipe-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px; 
}

.recipe-line .edit-input, .recipe-line .notebook-line, .recipe-line .delete-recipe-button {
    margin: 0.2rem 0rem; /* Removes default margin */
    padding: 0.2rem 0rem; /* Adjust padding as needed */
}

.recipe-line:first-of-type {
    margin-top: 16px; /* Adjust the value as needed */
}

.edit-input {
    /* margin-top: 7px; */
    display: flex;
    border: none;
    border-bottom: 1px solid #CC564D;
    background: transparent;
    width: 100%;
    font-size: inherit;   /* Inherits font size from parent */
    font-family: inherit; /* Inherits font family from parent */
    line-height: inherit; /* Inherits line height from parent */
    
}

.delete-recipe-button {
    width: 1.2rem;
    height: 1.2rem;
}


.notebook-line::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid #CC564D;
}

.footer {
    position: sticky; /* Changing from sticky to absolute */
    bottom: 0;
    width: 100%; /* Ensure it spans the entire width of its parent container */
    display: flex;
    justify-content: space-between;
    background-color: white; /* To ensure a white background */
    padding: 10px 20px; /* Adjust padding to align with the meal-box padding */
}

.footer .recommend-recipe-button {
    cursor: pointer;
}
.back-button {
    text-decoration: none;
    color: inherit;  /* To ensure the link has the same color as the surrounding text */
    display: inline-block;  /* This ensures the entire area of the link is clickable */
    
    width: 40px;             /* Width of the circle */
    height: 40px;            /* Height of the circle */
    border-radius: 50%;      /* Makes it circular */
    text-align: center;      /* To center the < symbol */
    line-height: 40px;       /* Vertically align the < symbol */
}
