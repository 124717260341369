* {
  box-sizing: border-box;
}

.main-container {
  width: 100%;
}

.Navigation-Bar{
  justify-content: space-around;
  display: flex;
  padding: .5rem;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  position: fixed;
  bottom: 0;
  font-size: .7rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #CC564D;
  font-style: normal;
  text-decoration:none;
  transition: color 0.3s;
}

.nav-item:hover{
  color: #a21107;
}

.login-logout-container {
  display: flex;
  justify-content: flex-end;
}

.login-logout-container .login-logout-button {
  display: flex;
  background-color: #f0e9d7;
  color: black;
  width:  clamp(5rem, 20vw, 10rem);
  height:  clamp(1.1rem, 8vw, 2.6rem);
  text-decoration-line: none;
  text-align: center;
  font-size: clamp(0.5rem, 4vw, 1.3rem);;
  border-radius: 15px;
  margin-right: 2vw;
  margin-top: .5rem;
  border: none;
  justify-content: center;
  align-items: center;
}

.login-logout-container .login-logout-button:hover {
  background-color: grey;
}

@media screen and (max-width: 300px) {
    .nav-item > span {
        display: none;
    }
    
}