.new-post-top-bar .back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    margin-left: 3vw;
    font-size: 2rem;
    width: 2rem;
    height: 2rem;
    background-color: transparent;
    border: .1rem solid black;
}

.post-container {
  max-width: 600px;
  margin: auto;
  /* font-family: Arial, sans-serif; */
}

.post-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #ccc;
}

.post-back-button {
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.post-header-title {
  flex-grow: 1;
  text-align: center;
}

.post-form {
  padding: 16px;
}

.post-form-input {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.post-form-textarea {
  height: 100px;
  resize: none;
}
.post-form-label {
    display: block;
    margin-bottom: 0.3rem;
    left: 1rem;
    font-size: 0.8rem;
    color:#aaaaaa;
}
.post-image-upload-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.post-image-upload-label {
  display: block;
  width: calc(33% - 0.5rem);
  height: 80px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  background-color: #f8f8f8;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.post-image-upload-placeholder {
  line-height: 76px;
  font-size: 24px;
  color: #cccccc;
}

.post-image-upload-container {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover; 
}

.post-image-upload-input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.post-submit-button {
  width: 100%;
  padding: 12px 0;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.post-location-group {
    position: relative;

    .location-icon{
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}
.post-time-group {
    position: relative;

    .time-icon{
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}
.post-error-message {
    color: red;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 0.8rem;
  }
