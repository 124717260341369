/* RecommendedRecipes.css */
.container {
    display: flex;
    height: 100vh; 
    padding: 20px;
    box-sizing: border-box;  /* This ensures padding is included in the height calculation */
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* ... other styles ... */
}

.recipe-title{
    color: #535353;
    text-decoration: underline;
    text-decoration-color: #CC564D; /* This will create the red underline */
    text-decoration-thickness: 0.2rem;
    /* ... offset the underline so it's not directly attatched to the title... */
    text-underline-offset: 0.5rem;
    margin-bottom: 2rem;
    margin-right: 3rem;
}

.recipe-text{
    color: #535353;
}

.recipe-card {
    border: 1px solid #e0e0e0;
    padding: 2rem;
    flex-grow: 1;
    max-height: 55%;
    position: relative; /* To position the footer inside it */
    margin-top: 3rem;
    margin-bottom: 1rem;

    box-shadow: 0 0.5rem 0.5rem 0 #e0e0e0;
    border-radius: 1rem;

    text-align: left;
    overflow-y: scroll;
}

.recipe-card select {
    width: 100%;
    margin-top: 10px;
    /* ... other styles ... */
}

.dropdown-container {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Align children in the center */
    width: 100%;
    margin-top: 1rem; /* Adjust as needed */
}

.dropdown {
    border: none;
    width: 90%; /* Account for the margin */
    padding: 1px 0;
    height: 30px;
    margin: 0.5rem 2rem;
}

.save-button {
    position: absolute;
    border: none;
    cursor: pointer;
    /* border-radius: 4px; */
    height: 3rem;
    font-size: 2rem;
    top: 2.7rem; /* Adjust as needed */
    right: 2rem; /* Adjust as needed */
    /* padding: 5px 15px; */
    color: #808080;
    background-color: rgba(0, 0, 0, 0);

}

.flip-page{
    position: absolute;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    height: 30px;
    bottom: 2rem; /* Adjust as needed */
    padding: 5px 15px;
    left: 50%; /* Set to half of the parent's width */
    transform: translateX(-50%);
}

.page-indicators {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 1rem;
    top: 0rem;
  }
  
  .page-dot {
    height: 0.5rem;
    width: 0.5rem;
    margin: 0 0.2rem;
    background-color: #808080; /* Non-active dot color */
    border-radius: 50%;
    display: inline-block;
  }
  
  .page-dot.active {
    background-color: #CC564D; /* Active dot color */
  }
  